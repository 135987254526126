import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import HojaMembretada from '../../../../../../../assets/Images/Hoja Membretada.png';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        fontFamily: ""
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: "right",
        marginLeft: 160,
    },
    subtitle: {
        fontSize: 15,
        marginBottom: "10px",
        marginTop: "0px",
        fontWeight: 'bold',
        textAlign: "center",
    },
    Values: {
        fontSize: 18,
        marginBottom: 10,
        fontWeight: 'bold',
        textAlign: "center",
    },
    dates: {
        fontSize: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: "40px",
    },
    logo: {
        width: 50,
        height: 50,
    },
    watermark: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        opacity: 1, // Ajusta la opacidad según tus necesidades
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        position: 'absolute',
        bottom: 25,
        left: 0,
        right: 0,
        textAlign: 'right',
        fontSize: 10,
        marginRight: "35px",
        fontWeight: "light",
        opacity: 0.5,
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        display: "flex"
    },
    tableSection: {
        flex: 4,
        borderWidth: 0,
    },
    totalAmount: {
        margin: "5px",
        flex: 0.5,
        display: "flex",
        flexDirection: "row",
        margin: "15px auto 0px auto",
        width: "400px",
        padding: 0
    },
    item: {
        margin: "5px",
        flex: "1",
    },
    table: {
        display: "table",
        borderWidth: 0,
        margin: "15px auto 0px auto",
    },
    row: {
        flexDirection: "row",
        display: "flex",
        margin: "0px auto 0px auto",
        width: "400px",
        borderWidth: 1,
    },
    cell: {
        margin: "0",
        borderStyle: "solid",
        textAlign: "center",
        flex: "1",
    },
});

// Crea un componente de celda de tabla
const TableCell = ({ children }) => (
    <View style={styles.cell}>
        <Text>{children}</Text>
    </View>
);

// Crea un componente de fila de tabla
const TableRow = ({ children }) => <View style={styles.row}>{children}</View>;

// Función que divide las filas en páginas según un número máximo de filas por página
const paginateData = (data, rowsPerPage) => {
    const pages = [];
    for (let i = 0; i < data.length; i += rowsPerPage) {
        pages.push(data.slice(i, i + rowsPerPage));
    }
    return pages;
};

const MyDocument = ({ data }) => {
    const rowsPerPage = 16; // Máximo de filas por página
    const paginatedRows = paginateData(data.SellTimeChart, rowsPerPage);

    return (
        <Document>
            {paginatedRows.map((pageRows, pageIndex) => (
                <Page key={pageIndex} style={styles.page} size="A4" wrap>
                    <View style={styles.watermark}>
                        <Image src={HojaMembretada} />
                    </View>
                    <Text style={styles.footer}>
                        Calle Becerra 70-B Col. Tacubaya{'\n'}
                        Alcaldía Miguel Hidalgo C.P. 11870{'\n'}
                        Tel. (55) 5511 6508 • contacto@drsecurity.net{'\n'}
                        www.drsecurity.net
                    </Text>
                    <View style={styles.header}>
                        <Text style={styles.title}>Reporte de Registros</Text>
                        <View>
                            <Text style={styles.dates}>Desde: {data.Dates.StartDate}</Text>
                            <Text style={styles.dates}>Hasta: {data.Dates.EndDate}</Text>
                        </View>
                    </View>
                    <View style={styles.section}>
                        {pageIndex > 0? <></> : <>

                            <View style={styles.totalAmount}>
                                <View style={styles.item}>
                                    <Text style={styles.subtitle}>Ventas:</Text>
                                    <Text style={styles.Values}>{data.Deposits.Value}</Text>
                                </View>
                                <View style={styles.item}>
                                    <Text style={styles.subtitle}>Personas:</Text>
                                    <Text style={styles.Values}>{data.NumberofPersons.Value}</Text>
                                </View>
                                <View style={styles.item}>
                                    <Text style={styles.subtitle}>Transacciones:</Text>
                                    <Text style={styles.Values}>{data.Transactions.Value}</Text>
                                </View>
                            </View>

                            {/* <View style={styles.totalAmount}>
                                <View style={styles.item}>
                                    <Text style={styles.subtitle}>Impuestos:</Text>
                                    <Text style={styles.Values}>{data.Taxes.Value}</Text>
                                </View>
                                <View style={styles.item}>
                                    <Text style={styles.subtitle}>Cargos por servicios:</Text>
                                    <Text style={styles.Values}>{data.ServiceCharge.Value}</Text>
                                </View>
                            </View> */}
                        </>
                        }



                        <View style={styles.tableSection}>
                            <View style={styles.table}>
                                <TableRow>
                                    <TableCell>{data.TableID}</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                </TableRow>

                                {pageRows.map((value, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{value.time}</TableCell>
                                        <TableCell>{value.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</TableCell>
                                    </TableRow>
                                ))}
                            </View>
                        </View>
                    </View>
                </Page>
            ))}
        </Document>
    );
};

export const CreatePDF = async(data, setPDF) => {
    pdf(<MyDocument data={data} />).toBlob().then(blob => {
        setPDF(URL.createObjectURL(blob));
    });
};
