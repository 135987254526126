export const SandboxprofilesId = 
{ "Campo o Asesor": "3354688A-A119-40D9-9C27-577F7885B277", //0: Campo o Asesor
  "Operador o Supervisor":"94DF578F-CAF9-449A-B4FC-17BFF652319E", //1 : Operador o Supervisor
  "Administrador":"DDFA3BA3-78C3-4AD3-953C-695931125B1B", //2: Administrador
   "Soporte":"E8DB369A-2A08-4B0C-8C77-6534336B06B5", //3:Soporte
  }

export const ProductionprofilesId = 
 { "Campo o Asesor":"3354688A-A119-40D9-9C27-577F7885B277", //0: Campo o Asesor
  "Operador o Supervisor":"94DF578F-CAF9-449A-B4FC-17BFF652319E", //1: Operador o Supervisor
  "Administrador":"DDFA3BA3-78C3-4AD3-953C-695931125B1B", //2: Administrador
  "Soporte":"13779DFE-2C57-4977-A6D8-F1F275295DF3", //3: Soporte
  }

export const ListOfRolesKeys = [
  "Campo o Asesor",
  "Operador o Supervisor",
  "Administrador",
  "Soporte",
]

const validatePermissions = (profileid, permissions) => {
  let permisionfounded = false;
  permissions.map((value) => {
    if (value.includes(profileid.toUpperCase())) {
      permisionfounded = true;
      return;
    }
  });
  return permisionfounded;
};



///TotalPaymentPermissions

export const TotalPaymentSelectorPermission = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Operador o Supervisor"], SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
    : [
        ProductionprofilesId["Operador o Supervisor"],
        ProductionprofilesId["Administrador"],
        ProductionprofilesId["Soporte"],
      ];

  return validatePermissions(user.idRol, permissions);
};
export const CompleteTransactionPermission = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
    : [ProductionprofilesId["Administrador"], ProductionprofilesId["Soporte"]];

  return validatePermissions(user.idRol, permissions);
};
export const RegularPaymentPermission = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Operador o Supervisor"], SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Operador o Supervisor"], ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

///AdministrationPermissions
export const AdministrationPermissions = (user, sandbox) => {
    if (!user) return false;
    const permissions = sandbox
      ? [SandboxprofilesId["Operador o Supervisor"], SandboxprofilesId["Administrador"],SandboxprofilesId["Soporte"]]
      : [ProductionprofilesId["Operador o Supervisor"], ProductionprofilesId["Administrador"],SandboxprofilesId["Soporte"]];
  
    return validatePermissions(user.idRol, permissions);
  };

export const DashboardPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Operador o Supervisor"], SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Operador o Supervisor"], ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

export const AnalyticsPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Operador o Supervisor"], SandboxprofilesId["Administrador"], SandboxprofilesId["Soporte"]]
    : [ProductionprofilesId["Operador o Supervisor"], ProductionprofilesId["Administrador"], SandboxprofilesId["Soporte"]];
    
  return validatePermissions(user.idRol, permissions);
};

export const ReportsPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Operador o Supervisor"], SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Operador o Supervisor"], ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

export const UsersPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};

export const ConfigurationsPermisions = (user, sandbox) => {
  if (!user) return false;
  const permissions = sandbox
    ? [SandboxprofilesId["Administrador"]]
    : [ProductionprofilesId["Administrador"]];

  return validatePermissions(user.idRol, permissions);
};
