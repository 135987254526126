import { Grid } from "@mui/material";
import InfoCard from "./Components/InfoCard/InfoCard";
import UseContentDashboard from "../hooks/UseContentDashboard";
import CustomLineChart from "./Components/Charts/LinearChart/LinearChart";
import BarChar from "./Components/Charts/BarChart/BarChar";
import MultipleBarChar from "./Components/Charts/BarChart/MultipleBarchar";
import { useEffect, useState, useContext } from "react";



const ContentDashboard = ({ filter = "today", specificfilter = null }) => {

  const {
    Deposits,
    ServiceCharge,
    Taxes,
    Transactions,
    SellTimeChart,
    NumberofPersons,
    TerminalObj,
    TransactionsUsersOBJ,
    SatQAmount,
    TaxesDRAmoun,
    ASURAmount,
    Income
  } = UseContentDashboard({ filter, specificfilter });

  const [xaxislabel, setxaxislabel] = useState("Horas");


  useEffect(() => {
    if (filter.includes("today")) {
      setxaxislabel("Horas");
    } else if (filter.includes("month")) {
      setxaxislabel("Fechas");
    } else if (filter.includes("year")) {
      setxaxislabel("Meses");
    } else {
      setxaxislabel("Tiempo");
    }
  }, [filter]);


  return (
    <>
      <Grid container spacing={3} >
        <Grid item xs={12} md={6} lg={3}>
          <InfoCard element={Transactions} />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <InfoCard element={NumberofPersons} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={Deposits} />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <InfoCard element={Income} />
        </Grid>

        <Grid item xs={12} md={4} lg={2.4}>
          <InfoCard element={Taxes} />
        </Grid>
        <Grid item xs={12} md={4} lg={2.4}>
          <InfoCard element={SatQAmount} />
        </Grid>

        <Grid item xs={12} md={4} lg={2.4}>
          <InfoCard element={TaxesDRAmoun} />
        </Grid>

        <Grid item xs={12} md={4} lg={2.4}>
          <InfoCard element={ServiceCharge} />
        </Grid>

        <Grid item xs={12} md={4} lg={2.4}>
          <InfoCard element={ASURAmount} />
        </Grid>

     


        <Grid item xs={12} md={12} lg={12}>
          <CustomLineChart
            title="Deposits Chart"
            description=""
            xAxisLabel={xaxislabel}
            yAxisLabel="$"
            data={SellTimeChart}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <BarChar title="Sales by terminal" data={TerminalObj} height={200} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MultipleBarChar title="Sales by adviser"  height={200} data={TransactionsUsersOBJ}/>
        </Grid>


      </Grid>

    </>
  );
};

export default ContentDashboard;