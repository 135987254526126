import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getStorage} from "firebase/storage";

let firebaseConfig = {
    apiKey: "AIzaSyB0s4EB-wjHiKxY12F5uDz0A11HI18l7lY",
    authDomain: "visitax-418015.firebaseapp.com",
    databaseURL: "https://visitax-418015-default-rtdb.firebaseio.com",
    projectId: "visitax-418015",
    storageBucket: "visitax-418015.appspot.com",
    messagingSenderId: "366157175785",
    appId: "1:366157175785:web:e5d9823e948cf4e0204978",
    measurementId: "G-Y5CXV8NX4G"
  };
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


  export const ConfigureFirebase = (firebaseConfig)=>{
    try{
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      const storage = getStorage(app);
      const auth = getAuth(app); 
      return ({messaging,storage,auth});
    }
    catch(e){return null}
  }


  export const authenticateWithBackendToken = async (firebaseToken, auth) => {
    try {
      const userCredential = await signInWithCustomToken(auth, firebaseToken);
      return userCredential.user;
    } catch (error) {
      console.error("Error al autenticar con el token:", error);
      throw error;
    }
  };  

  export const getTokenfb = (setTokenFound) => {
    return getToken(messaging,  { vapidKey: 'BMQEFP9KKdeJt8WLTK0rP9JXqLN1MeLRp3QEMTmfMa5rD4FrKmhqgiKbNSVZQrYCfzBCieTku49xVNciHttwNJw' }).then((currentToken) => {
      if (currentToken) {
  
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(currentToken);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      
      return  getToken(messaging,  { vapidKey: 'BMQEFP9KKdeJt8WLTK0rP9JXqLN1MeLRp3QEMTmfMa5rD4FrKmhqgiKbNSVZQrYCfzBCieTku49xVNciHttwNJw' }).then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          setTokenFound(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log('No registration token available. Request permission to generate one.');
          setTokenFound(currentToken);
          // shows on the UI that permission is required 
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
      });
      // catch error while creating client token
    });
     
  }

  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });