import { useContext, useEffect, useState } from 'react';
import { Context } from '../../context/Context'
import CircularProgress from '../Universals/CircularProgress';

function Amoount() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    const ivaRate = 0.16; // Tasa de IVA del 16%

    const serviceChargeTotal = datacontext.paymentAmounts.servicecharge * datacontext.Data.Datos.detalles.length;
    const ivaServiceCharge = serviceChargeTotal * ivaRate;
    const serviceChargeWithoutIva = serviceChargeTotal - ivaServiceCharge;
    const TaxesAmountCalc = ()=>{
        if(datacontext.paymentAmounts.taxesamount === 0 && Number(datacontext.Data.Datos.importe)>0){
            return (datacontext.Data.Datos.importe-serviceChargeWithoutIva-ivaServiceCharge )
        }
        else return datacontext.paymentAmounts.taxesamount;
    }

    return (
        <>
            {datacontext.myConfig.umaValue == null ? <CircularProgress /> : <>
                {(datacontext.ServiceChargeActive == 1) ? <>
                    <h2>{"Taxes Amount"}: ${TaxesAmountCalc()} MXN</h2>
                    <h2>{"Service Charge:"}: ${serviceChargeWithoutIva.toFixed(2)} MXN</h2>
                    <h2>{"IVA"}: ${ivaServiceCharge.toFixed(2)} MXN</h2>
                </> : <></>}
                <h2>{txt[59]}: ${datacontext.Data.Datos.importe} MXN</h2>
            </>}
        </>
    )
}

export default Amoount;
