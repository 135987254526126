import React, { useState, useEffect } from 'react';
import {
    Table as MUITable,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    TextField,
    Pagination,
    Skeleton,
    Button,
} from '@mui/material';

const Title = ({ title = "" }) => {
    return (
        <Typography
            component="h2"
            variant="h6"
            color="primary"
            align="center"
            gutterBottom
        >
            {title}
        </Typography>
    );
};

const Table = ({
    headers,
    content = [], // Asegúrate de que 'content' tenga un valor por defecto como un array vacío.
    totalElements,
    onCellClick = () => { },
    onPageChange = () => { },
    actionButtonTitle,
    onActionButtonClick = () => { },
    omitKeys = [],
    title = "",
    standardPagination = true
}) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [paginatedContent, setPaginatedContent] = useState([]);

    const itemsPerPage = 10;

    // Filtrar el contenido basado en el término de búsqueda y las llaves omitidas
    useEffect(() => {
        setPaginatedContent(content);
        setLoading(false);
    }, [content]);

    useEffect(() => {
        if (searchTerm === "") {
            setPaginatedContent(content);
        } else {
            const filtered = content.filter(row => {
                return Object.entries(row).some(([key, value]) => {
                    if (omitKeys.includes(key) || value === null) return false;
                    return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
                });
            });
            setPaginatedContent(filtered);
        }
        setCurrentPage(1);
    }, [searchTerm, content, omitKeys]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        if (!standardPagination) {
            setLoading(true);
            onPageChange(value);
        }
    };



    return (
        <Paper sx={{ borderRadius: 2, overflow: 'hidden' }}>
            <Title title={title} />
            <TextField
                label="Buscar"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TableContainer component={Paper}>
                {content.length === 0 ? (
                    <Typography variant="h6" align="center" sx={{ padding: 2 }}>
                        No hay datos disponibles
                    </Typography>
                ) : (
                    <MUITable sx={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header, index) => (
                                    <TableCell key={index} sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                                        {header}
                                    </TableCell>
                                ))}
                                {actionButtonTitle && (
                                    <TableCell sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}>
                                        Acción
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    {headers.map((_, index) => (
                                        <TableCell key={index}>
                                            <Skeleton variant="text" />
                                        </TableCell>
                                    ))}
                                    {actionButtonTitle && <TableCell><Skeleton variant="text" /></TableCell>}
                                </TableRow>
                            ) : !standardPagination ? (
                                // Caso 1: Si standardPagination es true
                                paginatedContent.map((row, rowIndex) => (
                                    <TableRow
                                        key={rowIndex}
                                        onClick={() => onCellClick(row)}
                                        sx={{
                                            cursor: 'pointer',
                                            '&:hover': {
                                                backgroundColor: '#e0f7fa',
                                            },
                                            backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#f9f9f9',
                                        }}
                                    >
                                        {Object.keys(row)
                                            .filter(key => !omitKeys.includes(key))
                                            .map((key) => (
                                                <>
                                                    {typeof row[key] === 'object' && row[key] !== null
                                                        ? <> </>// Convierte el objeto a string o maneja su renderizado
                                                        : <TableCell key={key}>
                                                            {row[key]}
                                                        </TableCell>}

                                                </>

                                            ))}
                                        {actionButtonTitle && (
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    color="primary"
                                                    sx={{
                                                        padding: '4px 8px',
                                                        margin: '0',
                                                        '&:hover': {
                                                            backgroundColor: '#e3f2fd',
                                                        },
                                                    }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        onActionButtonClick(row);
                                                    }}
                                                >
                                                    {actionButtonTitle}
                                                </Button>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))
                            ) : (
                                // Caso 2: Si standardPagination es false
                                paginatedContent.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                                    .map((row, rowIndex) => (
                                        <TableRow
                                            key={rowIndex}
                                            onClick={() => onCellClick(row)}
                                            sx={{
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    backgroundColor: '#e0f7fa',
                                                },
                                                backgroundColor: rowIndex % 2 === 0 ? '#ffffff' : '#f9f9f9',
                                            }}
                                        >
                                            {Object.keys(row)
                                                .filter(key => !omitKeys.includes(key))
                                                .map((key) => (
                                                    <>
                                                        {typeof row[key] === 'object' && row[key] !== null || key.includes("detailCompleted")
                                                            ? <> </>// Convierte el objeto a string o maneja su renderizado
                                                            : <TableCell key={key}>
                                                                {row[key]}
                                                            </TableCell>}

                                                    </>
                                                ))}
                                            {actionButtonTitle && (
                                                <TableCell>
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        sx={{
                                                            padding: '4px 8px',
                                                            margin: '0',
                                                            '&:hover': {
                                                                backgroundColor: '#e3f2fd',
                                                            },
                                                        }}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onActionButtonClick(row);
                                                        }}
                                                    >
                                                        {actionButtonTitle}
                                                    </Button>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                            )}
                        </TableBody>

                    </MUITable>
                )}
            </TableContainer>
            {content.length > 0 && (
                <Pagination
                    count={standardPagination ? Math.ceil(paginatedContent.length / itemsPerPage) : Math.ceil(totalElements / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ justifyContent: 'center', margin: '16px 0' }}
                />
            )}
        </Paper>
    );
};

export default Table;
