import { Grid, Skeleton, Modal, Typography, Button } from "@mui/material";
import Table from "./components/Table/Table";
import UseContentDashboard from "../hooks/UseContentDashboard";
import { useEffect, useState } from "react";
import { Context } from "../../../../../context/Context";
import { useContext } from "react";
import PayloadViewer from "./components/PayloadModal/PayloadViewer";
import PhysicalVoucherModal from "./components/PhysicalVoucherModal/PhysicalVoucherModal";
import { uploadFile } from "../../../../../context/firebase/storageCrud";
import { ConfigureFirebase,authenticateWithBackendToken } from "../../../../../context/firebase/firebaseconfig";
const Analytics = ({ filter = "today", specificfilter = null }) => {
  const datacontext = useContext(Context);
  const {
    TransactionsList,
    Incidents,
    alltransactionsactive,
    TransactionDetailsList,
    PayementsVouchers,
    Pagination,
    getIncidents,
    setIncidents,
    makeRequest,
  } = UseContentDashboard({ filter, specificfilter });

  const [requestCompleteTransaction, setRequestCompleteTransaction] = useState({
    state: false,
    id: "",
    loading: false,
    success: null, // null = no completado, true = exitoso, false = fallido
  });

  const handlePageChange = (newPage) => {
    console.log("Page changed to:", newPage);
    Pagination(newPage);
  };

  useEffect(() => {
    if (requestCompleteTransaction.state == true) {
      setIncidents({ ...Incidents, data: [], totalSum: 0 });
      datacontext.postCompleteTransaction(
        (response) => {
          getIncidents();
          setRequestCompleteTransaction({
            state: false,
            id: "",
            loading: false,
            success: response.data.details.code == 201 ||  response.data.details.code == 202 || response.data.details.code == 200 ? false : true,
          });
        },
        () => {
          getIncidents();
          setRequestCompleteTransaction({
            state: false,
            id: "",
            loading: false,
            success: false,
          });
        },
        requestCompleteTransaction.id
      );
    }
  }, [requestCompleteTransaction]);

  const [vouucherdata, setvoucherdata] = useState(null);
  const [transactiondata, seTransactiondata] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
  });
  const [transactiondatavoucher, seTransactiondatavoucher] = useState({
    payload: "",
    transactionid: "",
    voucher: "",
    paymentgateway: "",
  });
  const [openPayloadViewe, setopenPayloadViewer] = useState(false);

  const [openPayloadVieweTrans, setopenPayloadViewerTrans] = useState(false);
  const [openVoucherViewe, setOpenModalViewer] = useState(false);
  const [emailSendStatus, setEmailSendStatus] = useState(null);
  const [voucherstatus, setvoucherstatus] = useState(null);
  const handleTransactionSelected = (row) => {
    seTransactiondatavoucher({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
      paymentgateway: row.openpayKey,
    });
  };
  const handleOpenVoucherPicture = (row) => {
    setvoucherdata(row)
  };
  const handleComplete = (row) => {
    seTransactiondata({
      payload: JSON.parse(row.payload),
      transactionid: row.id,
      voucher: row.voucher,
    });
  };
  const handleCloseModal = () => {
    setRequestCompleteTransaction((prev) => ({
      ...prev,
      success: null,
      state: false,
    }));
    seTransactiondata({ payload: "", transactionid: "", voucher: "" });
  };
  useEffect(() => {
    if (transactiondata.payload) setopenPayloadViewer(true);
  }, [transactiondata]);
  useEffect(() => {
    if (transactiondatavoucher.payload) setopenPayloadViewerTrans(true);
  }, [transactiondatavoucher]);

  useEffect(() => {
    if (vouucherdata)  setOpenModalViewer(true);
  }, [vouucherdata]);
  const handleClose = () => {
    makeRequest();
    seTransactiondata({ payload: "", transactionid: "", voucher: "" });
    setopenPayloadViewer(false);
    seTransactiondatavoucher({
      payload: "",
      transactionid: "",
      voucher: "",
      paymentgateway: "",
    });
    setopenPayloadViewerTrans(false);
  };
  const onFinalActionButtonClicked = (data) => {
    setRequestCompleteTransaction({
      state: true,
      id: data.transactionid,
      loading: true,
      success: null,
    });
  };
  const onResenEmailClicked = (data) => {
    const EmailData = {
      voucher: data.voucher,
      email: data.newPayload.correo,
      importe: data.newPayload.importe,
      service_charge: data.newPayload.service_charge,
    };
    datacontext.postForwardEmail(
      (res) => {
        console.log(res);
        setEmailSendStatus(true); // Éxito
        
      },
      (resfailed) => {
        console.log(resfailed);
        setEmailSendStatus(false); // Error
      },
      EmailData
    );
  };

  const keysEditablesIncidents = [
    "correo",
    "telefono",
    "detalles.nombre",
    "detalles.apellido",
    "detalles.pasaporte",
  ];
  const keysVisiblesIncidents = [
    "personas",
    "importe",
    "service_charge",
    "manual",
    "correo",
    "telefono",
    "detalles",
  ];
  const keysEditablesTransactions = ["correo", "telefono"];
  const keysVisiblesTransactions = [
    "personas",
    "importe",
    "service_charge",
    "manual",
    "correo",
    "telefono",
    "detalles",
  ];
  const handleVoucherModalSubmit =   (imageFile, coment, voucher) => {
    const onOkresponse=()=>{
        setvoucherstatus(true);
        makeRequest();
    }
    const onFailResponse =()=>{
        setvoucherstatus(false);
    }

    const UpdatePicture =(url)=>{
        const payload = {...voucher,imageUrl:url,description:coment}
        datacontext.putPhysicalRecords(onOkresponse,onFailResponse,payload);
    }
    const UploadVoucher = (storage)=>{
        uploadFile(imageFile, "PhysicalVouchers/" + voucher.paymentGateway,storage)
          .then((resp) => UpdatePicture(resp))
          .catch((e) => {console.log(e); onFailResponse()})
      }
      
      const FirebaseConfigure = (res)=>{
        try{
          const firebaseConfig = res.data.data.firebaseConfig;
          const firebaseAuth = res.data.data.customToken;
          const {storage, auth}=ConfigureFirebase(firebaseConfig);
          authenticateWithBackendToken(firebaseAuth, auth).then(()=>{
            UploadVoucher(storage);
          }).catch((e) => {console.log(e);onFailResponse()})
          
        }
        catch(e){
          console.log("Fallo al configurar firebase",e);
          onFailResponse();
        }
      }
      

    datacontext.getFirebaseConfig(FirebaseConfigure,FirebaseConfigure);

  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Table
            headers={TransactionsList.headers && TransactionsList.headers}
            content={TransactionsList.data}
            totalElements={TransactionsList.totalSum}
            omitKeys={TransactionsList.omitkeys}
            onPageChange={handlePageChange}
            title="Transactions by voucher"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Details"
            onActionButtonClick={handleTransactionSelected}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={
              TransactionDetailsList.headers && TransactionDetailsList.headers
            }
            content={TransactionDetailsList.data}
            totalElements={TransactionDetailsList.totalSum}
            omitKeys={TransactionDetailsList.omitkeys}
            onPageChange={handlePageChange}
            title="Transactions by passport"
            standardPagination={alltransactionsactive}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={PayementsVouchers.headers && PayementsVouchers.headers}
            content={PayementsVouchers.data}
            totalElements={PayementsVouchers.totalSum}
            omitKeys={PayementsVouchers.omitkeys}
            onPageChange={handlePageChange}
            title="Physical transaction without image voucher"
            standardPagination={alltransactionsactive}
            actionButtonTitle="Update Picture"
            onActionButtonClick={handleOpenVoucherPicture}
          />
        </Grid>

        <Grid item xs={12}>
          {/* Muestra Skeleton mientras la solicitud de transacción está en proceso */}
          {requestCompleteTransaction.loading ? (
            <Skeleton variant="rectangular" width="100%" height={300} />
          ) : (
            <Table
              title="Incidents"
              headers={Incidents.headers && Incidents.headers}
              content={Incidents.data}
              totalElements={Incidents.totalSum}
              omitKeys={TransactionsList.omitkeys}
              onPageChange={handlePageChange}
              actionButtonTitle="Complete Transaction"
              onActionButtonClick={handleComplete}
            />
          )}
        </Grid>
      </Grid>

      {/* Modal para mostrar el resultado de la transacción */}
      {transactiondata.payload && (
        <PayloadViewer
          open={openPayloadViewe}
          onClose={handleClose}
          transactiondata={transactiondata}
          keysEditables={keysEditablesIncidents}
          keysVisibles={keysVisiblesIncidents}
          onFinalActionButtonClicked={onFinalActionButtonClicked}
        />
      )}
      {transactiondatavoucher.payload && (
        <PayloadViewer
          open={openPayloadVieweTrans}
          onClose={handleClose}
          transactiondata={transactiondatavoucher}
          keysEditables={keysEditablesTransactions}
          keysVisibles={keysVisiblesTransactions}
          onFinalActionButtonClicked={onResenEmailClicked}
          finalActionButtonLabel="Forward mail"
        />
      )}
      <PhysicalVoucherModal
        open={openVoucherViewe}
        onClose={()=>setOpenModalViewer(false)}
        onSubmit={handleVoucherModalSubmit}
        vouucherdata={vouucherdata}
      />
      <Modal
        open={requestCompleteTransaction.success !== null}
        onClose={handleCloseModal}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "16px",
            boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
            borderRadius: "8px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">
            {requestCompleteTransaction.success
              ? "Transaction completed successfully!"
              : "An error has occurred while completing the transaction."}
          </Typography>
          <Button
            onClick={handleCloseModal}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </div>
      </Modal>

      <Modal
        open={emailSendStatus !== null}
        onClose={() => setEmailSendStatus(null)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "16px",
            boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
            borderRadius: "8px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">
            {emailSendStatus
              ? "Email sent successfully!"
              : "An error has occurred while sending the email."}
          </Typography>
          <Button
            onClick={() => setEmailSendStatus(null)}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </div>
      </Modal>


      <Modal
        open={voucherstatus !== null}
        onClose={() => setvoucherstatus(null)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "16px",
            boxShadow: "0 3px 5px rgba(0,0,0,0.3)",
            borderRadius: "8px",
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6">
            {voucherstatus
              ? "Voucher has updated successfully!"
              : "An error has occurred while udating picture"}
          </Typography>
          <Button
            onClick={() => setvoucherstatus(null)}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </div>
      </Modal>


      

      
    </>
  );
};
export default Analytics;
