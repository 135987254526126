import * as React from "react";
import { Context } from "../../../context/Context";
import { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import AttributionIcon from "@mui/icons-material/Attribution";
import {
  SandboxprofilesId,
  ProductionprofilesId,
  ListOfRolesKeys,
} from "../../../permissions/permissions";
import Autocomplete from "@mui/material/Autocomplete";
import {
  SaveAlertDialog,
  SuccesUploadAlertDialog,
  DeleteDialog,
} from "../../Universals/Dialogs";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import UserDashboard from "./UserDashboard";
import DeleteIcon from "@mui/icons-material/Delete";

function createData(cons, user) {
  return { cons, user };
}

function Row(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [DeleteopenDialog, setDeleteOpenDialog] = React.useState(false);
  const [openDialogSucces, setOpenDialogSucces] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [savingchanges, setsavingchanges] = React.useState(false);
  const [deletinguser, setdeletinguser] = React.useState(false);
  const [copyRow, setCopyRow] = React.useState({ ...props.row.user });
  const [role, setRole] = React.useState(ListOfRolesKeys[0]);
  const [inputValue, setInputValue] = React.useState(props.row.user.role);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setOpenDialog(true);
  };

  const OnDataSaved = () => {
    setOpenDialogSucces(true);
  };
  const OnDataFailed = () => {
    setsavingchanges(false);
  };

  useEffect(() => {
    if (savingchanges)
      props.datacontext.putUser(OnDataSaved, OnDataFailed, {
        ...copyRow,
        idRole: props.datacontext.Sandbox
          ? SandboxprofilesId[inputValue]
          : ProductionprofilesId[inputValue],
      });
  }, [savingchanges]);

  useEffect(() => {
    if (deletinguser)
      props.datacontext.putUser(OnDataSaved, OnDataFailed, {
        ...props.row.user,
        isActive: false,
      });
  }, [deletinguser]);

  return (
    <React.Fragment>
      <SaveAlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        setsavingchanges={setsavingchanges}
      />
      <DeleteDialog
        open={DeleteopenDialog}
        setOpen={setDeleteOpenDialog}
        setsavingchanges={setdeletinguser}
      />
      <SuccesUploadAlertDialog
        open={openDialogSucces}
        setOpen={props.SetData}
      />
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {props.datacontext.LoggedUser.rol.includes("Admin") ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <ModeEditIcon />}
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>

        <TableCell align="center">{props.row.cons}</TableCell>
        <TableCell align="center">{props.row.user.role}</TableCell>

        <TableCell align="center">
          {props.row.user.name +
            " " +
            props.row.user.lastName +
            " " +
            props.row.user.fatherLastName +
            " " +
            props.row.user.motherLastName}
        </TableCell>
        <TableCell align="center">{props.row.user.userName}</TableCell>
        <TableCell align="center">
          {
            <AttributionIcon
              color={props.row.user.isActive ? "success" : "disabled"}
            />
          }
        </TableCell>
        <TableCell>
          {props.datacontext.LoggedUser.rol.includes("Admin") ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen2(!open2)}
            >
              {open ? <KeyboardArrowUpIcon /> : <PersonSearchIcon />}
            </IconButton>
          ) : (
            <></>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => {
                      setCopyRow({
                        ...copyRow,
                        name: e.target.value,
                      });
                    }}
                    required
                    margin="dense"
                    id="name"
                    name="name"
                    label="Name"
                    type="text"
                    value={copyRow.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => {
                      setCopyRow({
                        ...copyRow,
                        lastName: e.target.value,
                      });
                    }}
                    margin="dense"
                    id="lastname"
                    name="lastname"
                    label="Second Name"
                    type="text"
                    value={copyRow.lastName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => {
                      setCopyRow({
                        ...copyRow,
                        fatherLastName: e.target.value,
                      });
                    }}
                    required
                    margin="dense"
                    id="FatherLastName"
                    name="FatherLastName"
                    label="Father's Lastname"
                    type="text"
                    value={copyRow.fatherLastName}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    onChange={(e) => {
                      setCopyRow({
                        ...copyRow,
                        motherLastName: e.target.value,
                      });
                    }}
                    required
                    margin="dense"
                    id="MotherLastName"
                    name="MotherLastName"
                    label="Mother's Lastname"
                    value={copyRow.motherLastName}
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={(e) => {
                      setCopyRow({
                        ...copyRow,
                        userName: e.target.value,
                      });
                    }}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    value={copyRow.userName}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    value={role}
                    onChange={(event, newValue) => {
                      setRole(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    id="controllable-states-demo"
                    options={ListOfRolesKeys}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select a role" />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      setDeleteOpenDialog(true);
                    }}
                  >
                    <DeleteIcon />
                    Delete User
                  </IconButton>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <SaveIcon /> Save
              </Button>
            </Box>
          </Collapse>
          <Collapse in={open2} timeout="auto" unmountOnExit>
            <UserDashboard user={props.row.user} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function UsersTable({ data, setOpen, setData }) {
  const datacontext = useContext(Context);

  let empty = false;
  const rows = [];
  const setingData = () => {
    setData(null);
  };

  try {
    const createRows = () => {
      data.map((value, id) => {
        rows.push(createData(id + 1, value));
      });
    };
    createRows();
    empty = false;
  } catch (e) {
    empty = true;
  }
  return (
    <>
      {empty ? (
        <></>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {datacontext.LoggedUser.rol.includes("Admin") ? (
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(true)}
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </TableCell>
                  <TableCell align="center">Cons</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">E-mail</TableCell>
                  <TableCell align="center">Active</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, id) => (
                  <Row
                    key={id}
                    row={row}
                    SetData={setingData}
                    datacontext={datacontext}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
export default UsersTable;
