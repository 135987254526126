
import { useEffect, useState } from "react"
import { useContext } from "react";
import { Context } from "../../../../../context/Context";
import { getCurrentDate, month, year } from "../../../../../data/Datos";
import { myConfig } from "../../../../../data/config";

const UseContentDashboard = ({ filter = "today", specificfilter = null }) => {
    const UMAS = 1.88
    const UMASValue = 108.57
    const Dolar = 19.5
    const registPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1)
    const [Deposits, setDeposits] = useState({ Title: "Deposits", Value: "$-", SecundaryValue: "This is the deposits amount" });
    const [ServiceCharge, setSericeCharge] = useState({ Title: "Service Charge", Value: "$-", SecundaryValue: "This is the service charge amount",numericvalue :0 });
    const [SatQAmount, setSatQAmount] = useState({ Title: "SAT Q ", Value: "$-", SecundaryValue: "This is an approximate of the amount to be paid to SATQ considering 1.8 umas per QR",numericvalue :0 });
    const [TaxesDRAmoun, setTaxesDRAmount] = useState({ Title: "Taxes free", Value: "$-", SecundaryValue: "This is an approximate of the Taxes amount free ",numericvalue :0 });
    const [ASURAmount, setAsurAmount] = useState({ Title: "ASUR commission", Value: "$-", SecundaryValue: "This is an approximate of the amount to be paid to ASUR considering 1 dolar in $19.5" ,numericvalue :0});
    const [Income, setTotalIncome] = useState({ Title: "Total Income", Value: "$-", SecundaryValue: "This is an approximate total of income by adding the service charge, the tax-free sum and subtracting the asur share",numericvalue :0 });
    const [Transactions, setTransactions] = useState({ Title: "Transactions", Value: "-", SecundaryValue: "This is the total of transactions" ,numericvalue :0});
    const [NumberofPersons, setNumberofPersons] = useState({ Title: "Persons", Value: "-", SecundaryValue: "This is the total of persons or QR´s" ,numericvalue :0});
    const [Taxes, setTaxes] = useState({ Title: "Taxes", Value: "-", SecundaryValue: "This is the taxes amount" });
    const [SellTimeChart, setSellTimeChartData] = useState([]);
    const [TransactionsUsersOBJ, setTransactionsUsers] = useState({});
    const [TransactionDetails, setTransactionDetails] = useState([]);
    const [TerminalObj, setTerminalObject] = useState({});
    const [TableID,setTableId]=useState("Horas");
    const [TransactionsList, setTransactionsList] = useState({
        headers: ["Voucher", "Persons", "SaleAmount", "Service Charge", "Transaction Key", "Adviser", "Terminal", "Departure Date", "Date Create","Date Create Mex"],
        data: [],
        totalSum: 0,
        omitkeys: ["id", "updatedAt", "payment", "dateSatQ", "hourSatQ", "useridSatQ", "idSatQ", "payload"]
    });

    const [TransactionDetailsList, setTransactionDetailList] = useState({
        headers: ["Passport", "Service Charge", "Amount", "HashId", "Voucher", "Departure Date", "Date Create","Date Create Mex"],
        data: [],
        totalSum: 0,
        omitkeys: ["id"]
    });
    const [Incidents, setIncidents] = useState({
        headers: ["Voucher", "Persons", "SaleAmount", "Transaction Key", "Date Create","Date Create Mex"],
        data: [],
        totalSum: 0,
        omitkeys: ["id", "payment", "detailCompleted", "users", "updatedAt", "payload"]
    });

    const [PayementsVouchers, setPaymentsVoucher] = useState({
        headers: ["Transaction Key", "Description","Usuario"],
        data: [],
        totalSum: 0,
        omitkeys: ["id","idTransaction","lotClosing","imageUrl","idUser"]
    });
    const [Dates, setDates] = useState({ EndDate: "", StartDate: "" });

    const datacontext = useContext(Context);
    const alltransactionsactive = true;

    const ResetData = ()=>{
        setNumberofPersons({ ...NumberofPersons, data: [], Value: 0 ,numericvalue:0})
        setTransactions({ ...Transactions, data: [], Value: 0,numericvalue:0 })
        setTransactionsList({ ...TransactionsList, data: [], totalSum: 0 ,numericvalue:0});
        setTransactionDetails([]);
        setTransactionDetailList({ ...TransactionDetailsList, data: [], totalSum: 0 })
        setTransactionDetails([]);
        setTransactionDetailList({ ...TransactionDetailsList, data: [], totalSum: 0 })
        setDeposits({ ...Deposits, Value: "$-" ,numericvalue:0})
        setSericeCharge({ ...ServiceCharge, Value: "$-" ,numericvalue:0})
        setTaxes({ ...Taxes, Value: "$-" ,numericvalue:0})
        setSellTimeChartData([])
        setTransactionsUsers([]);
        setSatQAmount({...SatQAmount,  Value:  "$-",numericvalue:0})
        setAsurAmount({...ASURAmount,  Value:  "$-",numericvalue:0})
        setTaxesDRAmount({...TaxesDRAmoun,   Value:"$-",numericvalue:0})
        setTotalIncome({...Income, Value:"$-",numericvalue:0})
        setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
    }


    //////TRANSACTIONSPAGINATION
    const OnDataRecived = (response) => {
        const Responsedata = response.data.data;
        setNumberofPersons({ ...NumberofPersons, Value: Responsedata.totalPerson ,numericvalue:Responsedata.totalPerson})
        setTransactions({ ...Transactions, Value: Responsedata.totalSum ,numericvalue:Responsedata.totalSum})
        setTransactionsList({ ...TransactionsList, data: Responsedata.allPaymentRegister, totalSum: Responsedata.totalSum });
        const SATAmount = Responsedata.totalPerson*UMAS*UMASValue;
        const ASURAmountCalc = Responsedata.totalPerson*Dolar;
        setSatQAmount({...SatQAmount,  Value:  parseFloat(SATAmount.toFixed(2)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),numericvalue:SATAmount})
        setAsurAmount({...ASURAmount,  Value:  parseFloat(ASURAmountCalc.toFixed(2)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),numericvalue:ASURAmountCalc})
    }
    const OnDataFailed = (response) => {
        setNumberofPersons({ ...NumberofPersons, data: [], Value: 0 ,numericvalue:0})
        setTransactions({ ...Transactions, data: [], Value: 0,numericvalue:0 })
        setTransactionsList({ ...TransactionsList, data: [], totalSum: 0 ,numericvalue:0});
        setSatQAmount({...SatQAmount,  Value:  "$-",numericvalue:0})
        setAsurAmount({...ASURAmount,  Value:  "$-",numericvalue:0})
    }

    //////TRANSACTIONSDETAILS
    const OnDataTransactionDetails = (response) => {
        const Responsedata = response.data.data;
        setTransactionDetails(Responsedata);
        setTransactionDetailList({ ...TransactionDetailsList, data: Responsedata })

    }
    const OnDataTransactionFailed = (response) => {
        setTransactionDetails([]);
        setTransactionDetailList({ ...TransactionDetailsList, data: [], totalSum: 0 })
    }
    //////DEPOSITS
    const OnDataDepositRecived = (response) => {

        const Responsedata = response.data.data;

        if (Responsedata.hourSums) {
            const formattedData = Responsedata.hourSums.map(item => ({
                time: item.hour,
                amount: item.sum
            }));
            formattedData.sort((a, b) => a.time - b.time);
            setSellTimeChartData(formattedData);
        }
        if (Responsedata.monthSums) {
            const formattedData = Responsedata.monthSums.map(item => ({
                time: item.monthNumber + "/" + item.year,
                amount: item.sum
            })).reverse();

            setSellTimeChartData(formattedData)
        }
        if (Responsedata.daySums) {
            const formattedData = Responsedata.daySums.map(item => ({
                time: item.date,
                amount: item.sum
            })).reverse();

            setSellTimeChartData(formattedData)

        }
        if (Responsedata.yearSums) {

        }
        const TaxesCalc = (Responsedata.totalSum - Responsedata.totalServicharge)
        const depositsformated =Responsedata.totalSum.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
        setDeposits({ ...Deposits, Value: depositsformated ,numericvalue:Responsedata.totalSum})
        setSericeCharge({ ...ServiceCharge, Value: Responsedata.totalServicharge.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) ,numericvalue:Responsedata.totalServicharge})
        setTaxes({ ...Taxes, Value:TaxesCalc.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),numericvalue:TaxesCalc})
   


    }
    const OnDataDepositFailed = (response) => {
        setDeposits({ ...Deposits, Value: "$-" ,numericvalue:0})
        setSericeCharge({ ...ServiceCharge, Value: "$-" ,numericvalue:0})
        setTaxes({ ...Taxes, Value: "$-" ,numericvalue:0})
        setSellTimeChartData([])
    }
    //////TERMINALS
    const OnDataTerminals = (response) => {

        const Responsedata = response.data.data;
        const dataObject = Responsedata.allTerminalRegister.reduce((acc, item) => {
            acc[item.terminal] = item.total;
            return acc;
        }, {});
        setTerminalObject(dataObject);

    }
    const OnDataTerminalsFailed = (response) => {
        setTerminalObject({});
    }

    ///INCIDENTS
    const OnDataIncidentsRecived = (response) => {
        const Responsedata = response.data.data;
        if (Responsedata) {
            setIncidents({ ...Incidents, data: Responsedata, totalSum: Responsedata.lenght });
        }



    }
    const OnDataIncidentsFailed = (response) => {
        setIncidents({ ...Incidents, data: [], totalSum: 0 });
    }

        ///PHYSICAL PAYMENT
    const onPhysicalRecordRecived = (response) => {
            const Responsedata = response.data.data;
            if (Responsedata) {
                setPaymentsVoucher({ ...PayementsVouchers, data: Responsedata, totalSum: Responsedata.lenght});
            }
        }
    const OnPhysicalRecordFailed = (response) => {
        setPaymentsVoucher({ ...PayementsVouchers, data: [], totalSum: 0 });
    }
    

    ////TRANSACTIONS USERS

    const OnTransactionsUsersRecived = (response) => {
        const Responsedata = response.data.data;
        console.log(Responsedata);
        setTransactionsUsers(Responsedata);


    }
    const OnTransactionsUsersFailed = (response) => {
        setTransactionsUsers([]);
    }

    const Pagination = (newPage) => {
        setCurrentPage(newPage);
    }

    const makeRequest = () => {
        ResetData();
        let complementaryurl1 = ""
        let complementaryurl2 = ""
        let complementaryurl3 = ""
        let complementaryurl4 = ""


        if (filter.includes("today")) {
            complementaryurl1 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage
            complementaryurl2 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=H"
            complementaryurl3 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
            complementaryurl4 = "?StartDate=" + getCurrentDate() + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
            setTableId("Hora");
        }
        else if (filter.includes("month")) {
            complementaryurl1 = "?StartDate=" + year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage
            complementaryurl2 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=D"
            complementaryurl3 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
            complementaryurl4 = "?StartDate=" + +year() + "/" + month() + "/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
            setTableId("Dia");
        }
        else if (filter.includes("year")) {
            complementaryurl1 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&PageNumber=" + currentPage + "&PageSize=" + registPerPage
            complementaryurl2 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&filter=M"
            complementaryurl3 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59&Terminals=1"
            complementaryurl4 = "?StartDate=" + year() + "/01/01" + " 00:00:00&EndDate=" + getCurrentDate() + " 23:59:59"
            setTableId("Mes");
        }   
        else {
            if (specificfilter == null) return;
            if (specificfilter.type.includes("H"))  setTableId("Hora");
            if (specificfilter.type.includes("D"))  setTableId("Dia");
            if (specificfilter.type.includes("M"))  setTableId("Mes");
            if (specificfilter.type.includes("Y"))  setTableId("Año");        
            complementaryurl1 = specificfilter.url + "&PageNumber=" + currentPage + "&PageSize=" + registPerPage
            complementaryurl2 = specificfilter.url + "&filter=" + specificfilter.type
            complementaryurl3 = specificfilter.url + "&Terminals=1"
            complementaryurl4 = specificfilter.url
        }

        setDates({
            EndDate: complementaryurl1.split("StartDate=")[1].split("&")[0],
            StartDate: complementaryurl1.split("EndDate=")[1].split("&")[0]
        })

        datacontext.getTransactionUserRecords(OnTransactionsUsersRecived, OnTransactionsUsersFailed,complementaryurl4);
        datacontext.getFilterData(OnDataRecived, OnDataFailed, complementaryurl1, alltransactionsactive);
        datacontext.getTransactionDetails(OnDataTransactionDetails, OnDataTransactionFailed, complementaryurl1);
        datacontext.getSumData(OnDataDepositRecived, OnDataDepositFailed, complementaryurl2);
        datacontext.getTerminals(OnDataTerminals, OnDataTerminalsFailed, complementaryurl3);
        datacontext.getPhysicalPaymentRecord(onPhysicalRecordRecived,OnPhysicalRecordFailed);
    }


    useEffect(() => {
        makeRequest();
    }, [filter, specificfilter, currentPage])

    useEffect(() => {
        datacontext.getIncidents(OnDataIncidentsRecived, OnDataIncidentsFailed);
        datacontext.getPhysicalPaymentRecord(onPhysicalRecordRecived,OnPhysicalRecordFailed)
    }, [])
    const getIncidents = () => {
        datacontext.getIncidents(OnDataIncidentsRecived, OnDataIncidentsFailed);
    }

    
    useEffect(() => {
        if(Taxes.numericvalue>0 && SatQAmount.numericvalue>0){
            const DrFreeAmount = Taxes.numericvalue- SatQAmount.numericvalue
         setTaxesDRAmount({...TaxesDRAmoun,  Value: parseFloat(DrFreeAmount.toFixed(2)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),numericvalue:DrFreeAmount})
            
        }

        if(ServiceCharge.numericvalue>0 && ASURAmount.numericvalue>0 && Taxes.numericvalue>0 && SatQAmount.numericvalue>0){
            const DrFreeAmount = Taxes.numericvalue- SatQAmount.numericvalue;
            const IncomeCalc = DrFreeAmount+ServiceCharge.numericvalue-ASURAmount.numericvalue;
            setTotalIncome({...Income,  Value: parseFloat(IncomeCalc.toFixed(2)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),numericvalue:IncomeCalc})
        }
    }, [Taxes,SatQAmount,ASURAmount,ServiceCharge])





    return { Deposits, ServiceCharge, Taxes, Transactions, SellTimeChart, TerminalObj, TransactionsList, Incidents, NumberofPersons, alltransactionsactive, Dates, TransactionDetails, TransactionDetailsList,TransactionsUsersOBJ,SatQAmount,TaxesDRAmoun,ASURAmount,Income,TableID,PayementsVouchers,Pagination, getIncidents, setIncidents, makeRequest };




}
export default UseContentDashboard;