import React, { useState } from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ThemeProvider } from '@mui/material/styles';
import { Context } from '../../../context/Context';
import { useContext } from 'react';
import QRVisitax from "../../../assets/Images/VisitaxQR.jpg"
import Infography from "../../../assets/Images/Infografia.jpg"


//import Infographic from './Infographic/Infographic';
import { Box } from '@mui/material';



// Componente principal
const IconNavigation = () => {
    const [value, setValue] = useState(0);
    const datacontext = useContext(Context);

    // Función para renderizar el contenido basado en el valor de navegación
    const renderContent = () => {
        switch (value) {
            case 1:
                return  <InfographyContent />;
            case 2:
                return <QRContent />;
            default:
                return null; // No se muestra nada cuando está en "Home"
        }
    };

    const QRContent = () => {
        return (
            <Box sx={{ position: 'fixed', top: 0, left: 0, padding: 0, width: '100vw', height: '100vh', bgcolor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <img
                    src={QRVisitax}
                    alt="QR"
                    style={{
                        width: '100%',
                        maxHeight: '100vh',
                        objectFit: 'contain',

                    }}
                />
            </Box>

        );
    };

    const InfographyContent = () => {
        return (
            <Box sx={{ position: 'fixed', top: 0, left: 0, padding: 0, width: '100vw', height: '100vh', bgcolor: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 10 }}>
                <img
                    src={Infography}
                    alt="Infography"
                    style={{
                        width: '100%',
                        maxHeight: '100vh',
                        objectFit: 'contain',

                    }}
                />
            </Box>

        );
    };


    return (
        <ThemeProvider theme={datacontext.primaytheme}>
            <Box sx={{ display: 'flex', padding: 0 }}>
                {/* Contenido dinámico */}
                <Box sx={{ position: 'relative', padding: 0 }}>
                    {renderContent()}
                </Box>

                {/* Icon Navigation */}
                <BottomNavigation
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                    sx={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 20 }}
                >
                    <BottomNavigationAction label="Home" icon={<HomeIcon />} />
                    <BottomNavigationAction label="Infografía" icon={<InfoIcon />} />
                    <BottomNavigationAction label="QR" icon={<QrCodeIcon />} />
                </BottomNavigation>
            </Box>
        </ThemeProvider>

    );
};

export default IconNavigation;

