import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Context } from '../../../context/Context';
import { useContext } from 'react';


export default function ComplementariForm() {
    const datacontext = useContext(Context);
    const txt = datacontext.myConfig.txt[datacontext.lenguage];
    
    const regexName =/^[a-zA-ZñÑáéíóúÁÉÍÓÚ\s]*$/;
    const regexPhone = /^\d{0,15}$/;

    const handleChange = (prop) => (event) => {
        let update = false;
        if (prop === "holder_name" && regexName.test(event.target.value)) {
            update = true;
        } else if (prop === "email" ) {
            update = true;
            datacontext.Updatespecificdata("correo",event.target.value);
        } else if (prop === "phone_number" && regexPhone.test(event.target.value)) {
            update = true;
            datacontext.Updatespecificdata("telefono",event.target.value);
        }

        if (update) datacontext.setcard({
            ...datacontext.card,
            [prop]: event.target.value
        });


    };


    return (

        <Box
        component="form"
        sx={{
            display: 'flex',  // Añade Flexbox
            flexDirection: 'column',  // Alinea los elementos en una columna
            alignItems: 'center',  // Centra los elementos horizontalmente
            justifyContent: 'center',  // Centra los elementos verticalmente
            '& .MuiTextField-root': { m: 1, width: '95%' },
      
        }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id="outlined-basic"
                    label={txt[36] + ":"}
                    variant="outlined"
                    value={datacontext.card.holder_name}
                    onChange={handleChange('holder_name')}
                />
                <TextField
                    id="outlined-basic"
                    label={txt[14] + ":"}
                    variant="outlined"
                    value={datacontext.card.email}
                    onChange={handleChange('email')}
                />
                <TextField
                    id="outlined-basic"
                    label={"Phone Number :"}
                    variant="outlined"
                    value={datacontext.card.phone_number}
                    onChange={handleChange('phone_number')}
                />
            </div>
        </Box>

    );
}

