import * as React from "react";
import MasterCard from "../../assets/Images/Mastercard-logo.png";
import Visa from "../../assets/Images/Visa_Logo.png";
import AmericanExpress from "../../assets/Images/American_Express_logo.png";
import OpenPayLogo from "../../assets/Images/logo-openpay.im1669747112914im.avif";
import line from "../../assets/Images/divider-numU.svg";
import CardNumber from "./subcomponents/CardNumer";
import Dateexpire from "./subcomponents/Dateexpire";
import {
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
} from "@mui/material";
import CVV from "./subcomponents/CVV";
import { useLocation, useNavigate } from "react-router-dom";
import Amoount from "../Universals/Amount";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "../Universals/CircularProgress";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import HeaderNologo from "../Universals/HeaderNoLogo";
import { useOPPayment } from "./hooks/useOPPayment";
import ComplementariForm from "./subcomponents/ComplementariForm";
import { ThemeProvider } from "@mui/material/styles";
import { Link } from "@mui/material";
import CameraModal from "./subcomponents/CameraModal";

import {
  TotalPaymentSelectorPermission,
  CompleteTransactionPermission,
  RegularPaymentPermission,
} from "../../permissions/permissions";

function TotalPayment() {
  const datacontext = useContext(Context);
  const txt = datacontext.myConfig.txt[datacontext.lenguage];
  const location = useLocation(); // Obtener la ubicación actual
  const queryParams = new URLSearchParams(location.search);
  const [id, setID] = useState(queryParams.get("id"));
  const navigate = useNavigate();

  // Nuevo estado para el tipo de pago
  const [paymentType, setPaymentType] = useState("");
  const [approvalCode, setApprovalCode] = useState("");
  const [coment, setComment] = useState(
    "Se completa transaccion con numero de ticket: "
  );
  const [selectorPermision, setSelectorPermision] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [picture, setPicture] = useState(null);

  // Abrir y cerrar el Modal
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleImageTaken = (file) => {
    setPicture(file);
  };

  useEffect(() => {
    if (datacontext.myConfig.allconfigArray) {
      if (id) {
        const tokenId = id;
        CompletePay(tokenId);
      } else configureApi();
    }
  }, [datacontext.myConfig]);

  useEffect(() => {
    //if (datacontext.LoggedUser && !datacontext.LoggedUser.rol.includes("Campo"))
    if (
      TotalPaymentSelectorPermission(
        datacontext.LoggedUser,
        datacontext.Sandbox
      )
    )
      setSelectorPermision(true);
    else {
      setSelectorPermision(false);
      setPaymentType("digital");
    }
  }, [datacontext.LoggedUser]);

  useEffect(() => {
    if (id) return;
    try {
      if (datacontext.Data.Datos.detalles.length === 0 && !id)
        navigate("/TravelData");
    } catch (e) {
      console.log(e);
    }
  }, []);

  const [cardtype, setCardType] = useState("Unknown");
  const [expireValidation, setexpireValidation] = useState(false);
  const [DataError, setdataError] = useState(false);
  const [CvvValidation, setCvvValidation] = useState(false);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [paymentFail, setPaymentFail] = useState(null);

  const cardSelector = () => {
    switch (cardtype) {
      case "visa":
        return <img className="visa" src={Visa} />;
      case "mastercard":
        return <img className="visa" src={MasterCard} />;
      case "amex":
        return <img className="visa" src={AmericanExpress} />;
      default:
        return <></>;
    }
  };

  const PaymentValidation = (manual = false) => {
    const cardValidation = cardtype !== "Unknown";
    const nameValid = datacontext.card.holder_name.length > 1;
    const regexEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const emailValid = regexEmail.test(datacontext.card.email);
    if (manual && emailValid) return true;
    if (
      cardValidation &&
      expireValidation &&
      CvvValidation &&
      nameValid &&
      emailValid
    ) {
      setdataError(false);
      return true;
    }
    setdataError(true);
    return false;
  };

  const ManageApiAsk = () => {
    if (paymentType === "physical" || paymentType === "completetransaction") {
      if (
        approvalCode.length > 5 &&
        approvalCode.length < 26 &&
        PaymentValidation(true)
      ) {
        setWaitingResponse(true);
        setdataError(false);
        CompletePay(
          approvalCode,
          true,
          paymentType === "completetransaction" ? coment : "",
          picture
        );
      } else {
        setdataError(true);
      }
    } else {
      if (PaymentValidation(false)) {
        setWaitingResponse(true);
        makePayment();
      }
    }
  };

  const { configureApi, makePayment, CompletePay } = useOPPayment({
    setPaymentFail,
    setWaitingResponse,
    setID,
  });

  const printTotal = () => {
    try {
      return datacontext.Data.Datos.importe;
    } catch (e) {
      console.log(e);
      return <></>;
    }
  };

  const handleCheckboxChange = () => {
    try {
      // Verifica si promociones_servicios es nulo o indefinido
      const promocionesServicios =
        datacontext.Data?.Datos?.promociones_servicios;
      // Si es nulo o indefinido, setear a true
      const newValue =
        promocionesServicios !== undefined ? !promocionesServicios : true;
      // Actualiza con el nuevo valor
      datacontext.Updatespecificdata("promociones_servicios", newValue);
    } catch (err) {
      // Captura cualquier error y lo guarda en el estado
      //setError("Ocurrió un error al intentar actualizar promociones y servicios.");
      console.error(err);
    }
  };

  return (
    <>
      {waitingResponse || id ? <CircularProgress /> : <></>}
      <div className={waitingResponse || id ? "workzoneopacity" : "workzone"}>
        <HeaderNologo navigate="/ConfirmationUsers" />
        <h1>{txt[44]}</h1>

        {id ? (
          <></>
        ) : (
          <>
            <h5>${printTotal()} MXN</h5>
            <h3>{txt[45]}</h3>

            <div className="cardzone1">
              <div className="divlogos">
                <img className="visa" src={Visa} />
                <img className="mastercard" src={MasterCard} />
                <img className="mastercard" src={AmericanExpress} />
                <img className="openpay" src={OpenPayLogo} />
              </div>
            </div>

            <>
              {DataError ? (
                <Alert severity="warning">
                  <AlertTitle>{txt[46]}</AlertTitle>
                  {txt[47]}
                </Alert>
              ) : (
                <></>
              )}
              <ThemeProvider theme={datacontext.primaytheme}>
                <div id="PayForm">
                  {selectorPermision && (
                    <FormControl fullWidth>
                      <InputLabel id="payment-type-label">
                        Choose your payment method
                      </InputLabel>
                      <Select
                        labelId="payment-type-label"
                        id="payment-type"
                        value={paymentType}
                        onChange={(e) => setPaymentType(e.target.value)}
                        label="Choose your payment method"
                      >
                        {RegularPaymentPermission(
                          datacontext.LoggedUser,
                          datacontext.Sandbox
                        ) && (
                          <MenuItem value={"physical"}>
                            Pay with physical card
                          </MenuItem>
                        )}

                        {RegularPaymentPermission(
                          datacontext.LoggedUser,
                          datacontext.Sandbox
                        ) && (
                          <MenuItem value={"digital"}>
                            Pay with digital card
                          </MenuItem>
                        )}

                        {CompleteTransactionPermission(
                          datacontext.LoggedUser,
                          datacontext.Sandbox
                        ) && (
                          <MenuItem value={"completetransaction"}>
                            Complete Transaction
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  )}

                  {(paymentType === "physical" ||
                    paymentType === "completetransaction") && (
                    <>
                      <TextField
                        fullWidth
                        label="Enter approval code"
                        value={approvalCode}
                        onChange={(e) => {
                          if (e.target.value.length < 26) {
                            setApprovalCode(e.target.value);
                          }
                        }}
                      />
                      {picture && (
                        <img
                          id="passportview"
                          src={URL.createObjectURL(picture)}
                        ></img>
                      )}
                      <Button onClick={handleOpenModal}>
                        Take Voucher Picture
                      </Button>

                      <CameraModal
                        open={isModalOpen}
                        onClose={handleCloseModal}
                        onImageTaken={handleImageTaken}
                      />
                    </>
                  )}

                  {paymentType === "completetransaction" && (
                    <>
                      <TextField
                        fullWidth
                        multiline
                        label="Enter a coment"
                        value={coment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    </>
                  )}

                  {paymentType === "digital" && (
                    <>
                      {" "}
                      <Stack direction="row" spacing={2}>
                        <CardNumber
                          setCardType={setCardType}
                          waitingResponse={waitingResponse}
                        />
                        <div className="divlogos">{cardSelector()}</div>
                      </Stack>
                      <Stack direction="row" spacing={2}>
                        <Dateexpire
                          setexpireValidation={setexpireValidation}
                          waitingResponse={waitingResponse}
                        />
                        <CVV
                          setCvvValidation={setCvvValidation}
                          waitingResponse={waitingResponse}
                        />
                      </Stack>
                    </>
                  )}
                  <ComplementariForm />
                </div>

                <input
                  type="checkbox"
                  checked={
                    datacontext.Data?.Datos?.promociones_servicios ?? true
                  }
                  className="terms"
                  value="checkbox"
                  onChange={handleCheckboxChange}
                />
                <Link
                  className="terms"
                  htmlFor="terms"
                  onClick={handleCheckboxChange}
                  style={{ textDecoration: "none" }}
                >
                  {txt[63]
                    ? txt[63]
                    : "I agree to receive promotions and information"}
                </Link>
              </ThemeProvider>
            </>
          </>
        )}

        {paymentFail != null ? (
          <Alert severity={"error"}>
            <AlertTitle>{txt[33]}</AlertTitle>
            {paymentFail}
          </Alert>
        ) : (
          <></>
        )}
        <img className="divider" src={line}></img>
        {id ? (
          <>
            <h1>Completing Transaction...</h1>
          </>
        ) : (
          <>
            <div id="pagos">
              <Amoount />
              {waitingResponse ? (
                <></>
              ) : (
                <button className="stylebtn1" onClick={ManageApiAsk}>
                  {txt[49]}
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default TotalPayment;
